import { useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddlware from 'redux-thunk';
import reducers from '../reducers';

const middlewares = [thunkMiddlware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);
const initialState = {};

// eslint-disable-next-line no-unused-vars
function _getStoreForType() {
  // 型用
  return createStore(reducers, composedEnhancers);
}

/**
 * @typedef {ReturnType<ReturnType<typeof _getStoreForType>['getState']>} RootState
 */

/** @type {import('redux').Store<RootState>} */
let store;
if (process.env.REACT_APP_MODE === 'development') {
  store = createStore(reducers, initialState, composedEnhancers); // development
} else {
  store = createStore(reducers, initialState, middlewareEnhancer); // production
}

export default store;

/**
 * @type {import('react-redux').TypedUseSelectorHook<RootState>}
 */
export const useAppSelector = useSelector;

/**
 * @type {() =>
 * import('redux-thunk').ThunkDispatch<any, unknown, import('redux').Action<any>>
 * & import('redux').Dispatch<any>
 * }
 */
export const useAppDispatch = useDispatch;
