/** @param {number} val */
const pxToRem = (val) => {
  return `${val / 4}rem`;
};

// metrics in "rem" not "px"

/**
 * MUI Typography
 * @see https://mui.com/material-ui/customization/typography/
 */
const typography = {
  fontFamily: '"Noto Sans JP", sans-serif',
  errorNumber: {
    fontSize: pxToRem(178),
    fontWeight: 600,
  },
  error: {
    fontSize: pxToRem(120),
    fontWeight: 600,
  },
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 600,
  },
  h2: {
    fontSize: pxToRem(42),
    fontWeight: 600,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: 600,
  },
  h4: {
    fontSize: pxToRem(30),
    fontWeight: 600,
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 600,
  },
  h6: {
    fontSize: pxToRem(18),
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: 300,
  },
};

export default typography;
