const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email: 'The email format is invalid.',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      login: 'Login',
      signup: 'Signup',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Password?',
      email_address: 'Email Address',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
    },
    pages: {
      header_title: {
        title: 'Mican Pay Bank Account registration',
      },
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        sub_heading: 'To recover your account, please enter your email address below.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        sub_heading: 'Page Not Found',
        sub_heading_2:
          'The link you tapped is broken, or the page has been deleted or may have been changed',
        back_to_top: 'Back to Top Page',
      },
      not_supported: {
        sub_heading: 'Something technical went wrong',
        sub_heading_2: 'Server Error',
        back_to_top: 'Back to Top Page',
      },
      bank_account: {
        content:
          'In order to register an account, you will be authenticated by the online account transfer acceptance service. (This is not an application for account transfer)',
        go_to_bank_site: 'Go to Bank Site',
      },
      bank_confirmation: {
        title: 'Account registration completed',
        go_to_app_button: 'Go to app',
        charge_button: 'Charge',
      },
      ddd_complete: {
        header: 'みきゃんアプリ',
        message1: '画面は自動で遷移します。',
        message2: '※自動で遷移しない場合は',
        link: 'こちら',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Edit Profile',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        why_heading: 'Why use Base Template?',
        docker: {
          heading: 'Flexible Environment',
          description:
            'Eliminate the "it works on my machine" problem once and for all. Spend less time setting up environments, debugging environment-specific issues, and a more portable and easy-to-set-up codebase.',
        },
        react: {
          heading: 'Fast & Intuitive UI',
          description:
            'ReactJS is extremely intuitive to work with and provides interactivity to the layout of any UI. It is composable so we can utilize these components and integrate them into one place. Hence the code becomes a lot more maintainable and flexible.',
        },
        laravel: {
          heading: 'Powerful API',
          description:
            "Utilizing Laravel's API feature for easy backend API development. It's quick, simple with easy 3rd Party integrations and libraries.",
        },
        our_customers_heading: 'Our Clients',
        reviews_heading: 'What our clients say',
        see_all_reviews: 'See All Reviews',
        call_to_action: 'Accelerate your application development today!',
      },
      about: {
        main_heading: 'Our Story',
        sub_heading:
          'We work together to design, create and produce works that we are proud of for those we believe in.',
        meet_the_team: 'Meet the team',
        team_description:
          'Thoughtfulness, originality, and attention to detail are the basis for every product we design, build, and market.',
        our_mission: 'Our Mission',
        mission_description:
          'Our mission is to spread the excellence of technology with quality service and products valuing the business trend and proposition with people centric culture and behavior.',
        our_activities: 'Our Activities',
        activities_description: 'Never get so busy making a living that you forget to make a life.',
      },
    },
    menu: {
      home: 'Home',
      about: 'About',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
    },
    table: {
      no_data: 'No data.',
    },
  },
};

export default en;
