const routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/NotFound',
    error: true,
    title: 'ページが見つかりません',
  },
  {
    path: '/',
    component: 'pages/guest/bankAccount',
    error: false,
    title: 'みきゃんアプリ',
  },
  {
    path: '/bank-confirmation',
    component: 'pages/guest/bankConfirmation',
    error: false,
    title: 'みきゃんアプリ',
  },
  {
    path: '/ddd-complete',
    component: 'pages/guest/DDDComplete',
    error: false,
    title: 'みきゃんアプリ',
  },
  {
    path: '/not-found',
    component: 'pages/NotFound',
    error: true,
    title: 'みきゃんアプリ',
  },
  {
    path: '/not-supported',
    component: 'pages/NotSupported',
    error: true,
    title: 'エラーが発生しました。時間を置いてやり直してください。',
  },
];

export default routes;
