/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/breakpoints/
 */
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 480,
    tablet: 768,
    laptop: 1024,
    desktop: 1200,
  },
};

export default breakpoints;
