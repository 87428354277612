import 'assets/css/index.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Store from './redux/store';
import Router from './router';
import theme from './theme';

function App() {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
