import * as types from './actionTypes';

const initialState = {
  isFetching: false,
  isSuccess: false,
  isFailed: true,
  isRegistered: false,
  error: null,
  bankDetails: null,
};

// Bank Details
function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.BANK_DETAILS_PENDING:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        error: null,
        bankDetails: null,
      };
    case types.BANK_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        error: null,
        bankDetails: payload,
      };
    case types.BANK_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        bankDetails: null,
        error: payload,
      };

    case types.SUBMIT_BANK_DETAILS_PENDING:
      return {
        ...state,
        isFetching: true,
        isRegistered: false,
        isFailed: true,
        error: null,
        bankDetailsResult: null,
      };
    case types.SUBMIT_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isRegistered: true,
        isFailed: false,
        error: null,
        bankDetailsResult: payload,
      };
    case types.SUBMIT_BANK_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        isRegistered: false,
        isFailed: true,
        error: payload,
      };
    case types.UNMOUNT_BANK_ACCOUNT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
